.amg-fmg-2a *:nth-child(1) {
  grid-column: 1 / span 6;
  z-index: 1;
}

.amg-fmg-2a *:nth-child(2) {
  align-self: end;
  grid-column: span 8 / -1;
  margin-top: 25%;
  z-index: 0;
}

.amg-fmg-2b *:nth-child(1) {
  grid-column: 1 / span 10;
}

.amg-fmg-2b *:nth-child(2) {
  align-self: end;
  grid-column: span 4 / -1;
  margin-top: 220%;
}

.amg-fmg-2c *:nth-child(1) {
  grid-column: 1 / span 8;
}

.amg-fmg-2c *:nth-child(2) {
  align-self: end;
  grid-column: span 6 / -1;
  margin-top: 155%;
  z-index: 0;
}

.amg-fmg-2d *:nth-child(1) {
  grid-column: 1 / span 8;
}

.amg-fmg-2d *:nth-child(2) {
  align-self: end;
  grid-column: span 6 / -1;
  margin-top: 155%;
}

.amg-fmg-2e *:nth-child(1) {
  grid-column: 1 / span 8;
}

.amg-fmg-2e *:nth-child(2) {
  align-self: end;
  grid-column: span 7 / -1;
  margin-top: 100%;
  z-index: 0;
}

.amg-fmg-3a *:nth-child(1) {
  grid-column: 1 / span 8;
}

.amg-fmg-3a *:nth-child(2) {
  grid-column: span 6 / -1;
  margin-top: 150%;
  z-index: 0;
}

.amg-fmg-3a *:nth-child(3) {
  grid-column: span 7 / -4;
  margin-top: 230%;
  z-index: 1;
}

.amg-fmg-3b *:nth-child(1) {
  grid-column: 2 / span 7;
}

.amg-fmg-3b *:nth-child(2) {
  grid-column: span 5 / -1;
  margin-top: 100%;
  z-index: 1;
}

.amg-fmg-3b *:nth-child(3) {
  grid-column: 1 / span 10;
  margin-top: 80%;
  z-index: 0;
}

.amg-fmg-3c *:nth-child(1) {
  grid-column: 1 / span 10;
}

.amg-fmg-3c *:nth-child(2) {
  grid-column: span 6 / -1;
  margin-top: 100%;
  z-index: 1;
}

.amg-fmg-3c *:nth-child(3) {
  grid-column: 1 / span 4;
  margin-top: 250%;
  z-index: 0;
}

.amg-fmg-3d *:nth-child(1) {
  grid-column: 1 / span 12;
}

.amg-fmg-3d *:nth-child(2) {
  grid-column: span 5 / -2;
  margin-top: 120%;
  z-index: 1;
}

.amg-fmg-3d *:nth-child(3) {
  grid-column: 1 / span 5;
  margin-top: 230%;
  z-index: 0;
}

.amg-fmg-3e *:nth-child(1) {
  grid-column: 1 / span 12;
}

.amg-fmg-3e *:nth-child(2) {
  grid-column: span 7 / -2;
  margin-top: 120%;
  z-index: 1;
}

.amg-fmg-3e *:nth-child(3) {
  grid-column: 2 / span 7;
  margin-top: 160%;
  z-index: 0;
}
