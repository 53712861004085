/* Long words should not break the design */
* {
  word-wrap: break-word;
}

/* Make sure all images takes the full width of the device */
img {
  width: 100%;
}

/* Set the default size and the minimum width of all SVG */
svg {
  height: 1em;
  min-width: 16px;
  width: 1em;
}

/* Spacing between each component: all direct components of .amg-page except the last child */
.amg-page > *:not(:last-child) {
  margin-bottom: 64px;
}

/* Override default value rgba(0, 0, 0, 0.5) of the Drawer Backdrop */
.MuiDrawer-root .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Set the background color of the Drawer item as soon as the user clicks on it */
.MuiDrawer-root .MuiListItem-root.Mui-selected,
.MuiDrawer-root .MuiListItem-root.Mui-selected:hover {
  background-color: #b21e23;
  box-shadow: 0 0 16px 0 #b21e23;
}

/* Enable browser handling of all panning and zooming gestures */
#root .slick-slider {
  touch-action: auto;
}
