/* Animation used for the Activity Stream skeleton */
@keyframes shine-skeleton {
  0%, 30% {
    background-position: -200px;
  }
  70%, 100% {
    background-position: 2000px;
  }
}

/* Animation for the loader AMG, same as the one on the main website */
@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Stop the infinite-scroll-component to automatically hide the "releaseToRefreshContent" on touchend */
.is-fetching > div:first-child > div:first-child {
  transform: translate3d(0px, 80px, 0px) !important;
}
