/* Text Light */
@font-face {
  font-family: 'MBCorpoSText-Light-Web';
  font-display: swap;
  src: local('MBCorpoSText-Light-Web'), url(../fonts/MBCorpoSText-Light-Web.woff2) format('woff2');
}

/* Text Regular */
@font-face {
  font-family: 'MBCorpoSText-Regular-Web';
  font-display: swap;
  src: local('MBCorpoSText-Regular-Web'), url(../fonts/MBCorpoSText-Regular-Web.woff2) format('woff2');
}

/* Text Bold */
@font-face {
  font-family: 'MBCorpoSText-Bold-Web';
  font-display: swap;
  src: local('MBCorpoSText-Bold-Web'), url(../fonts/MBCorpoSText-Bold-Web.woff2) format('woff2');
}

/* Title Regular */
@font-face {
  font-family: 'MBCorpoSTitle-Regular-Web';
  font-display: swap;
  src: local('MBCorpoSTitle-Regular-Web'), url(../fonts/MBCorpoSTitle-Regular-Web.woff2) format('woff2');
}

/* Title Bold */
@font-face {
  font-family: 'MBCorpoSTitle-Bold-Web';
  font-display: swap;
  src: local('MBCorpoSTitle-Bold-Web'), url(../fonts/MBCorpoSTitle-Bold-Web.woff2) format('woff2');
}

/* Title ExtraBold */
@font-face {
  font-family: 'MBCorpoSTitle-ExtraBold-Web';
  font-display: swap;
  src: local('MBCorpoSTitle-ExtraBold-Web'), url(../fonts/MBCorpoSTitle-ExtraBold-Web.woff2) format('woff2');
}

/* Page title inside the App Bar */
h1 {
  font-family: 'MBCorpoSTitle-ExtraBold-Web', Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
}

h2 {
  font-family: 'MBCorpoSTitle-ExtraBold-Web', Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-size: 30px;
  line-height: 32px;
  font-weight: 400;
}

h3 {
  font-family: 'MBCorpoSTitle-Bold-Web', Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-size: 30px;
  line-height: 33px;
  font-weight: 400;
}

h4 {
  font-family: 'MBCorpoSTitle-Bold-Web', Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-size: 28px;
  line-height: 30px;
  font-weight: 400;
}

h5 {
  font-family: 'MBCorpoSTitle-Bold-Web', Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-size: 22px;
  line-height: 24px;
  font-weight: 400;
}

h6 {
  font-family: 'MBCorpoSText-Bold-Web', Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
}

div, p {
  font-family: 'MBCorpoSText-Regular-Web', Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-size: 16px;
  line-height: 21px;
}
